
<template>
    <div class="gibs-content-form-container" v-if="doneLoading && props.showControl">
        <div :class="modalStyle">
            <h3 class="gibs-form-title">{{ sentenceCase(props.Title) }}</h3>
            <hr class="gibs-form-divider-horizontal" />
            <div v-if="isDebugMode()">
                <p>Programme Id: {{ config?.ProgrammeId }}</p>
                <p>Programme Name: {{ config?.ProgrammeName }}</p>
                <p>Is Free Programme: {{ config?.IsFreeProgramme }}</p>
                <p>Form Level: {{ config?.FormLevel }}</p>
            </div>
            <div v-if="isDebugMode()" class="flex items-center justify-between">
                <label class="flex items-center cursor-pointer">
                    <div class="relative">
                        <input type="checkbox" v-model="showItemDetails" class="sr-only">
                        <div class="block bg-gray-600 w-7 h-4 rounded-full"></div>
                        <div class="dot absolute left-0.5 top-0.5 bg-white w-3 h-3 rounded-full transition"></div>
                    </div>
                    <div class="ml-3 text-gray-700 font-medium">Form Item Details</div>
                </label>

                <label class="flex items-center cursor-pointer">
                    <div class="relative">
                        <input type="checkbox" v-model="showSortedFormFields" class="sr-only">
                        <div class="block bg-gray-600 w-7 h-4 rounded-full"></div>
                        <div class="dot absolute left-0.5 top-0.5 bg-white w-3 h-3 rounded-full transition"></div>
                    </div>
                    <div class="ml-3 text-gray-700 font-medium">All Sorted Form Fields</div>
                </label>

                <label class="flex items-center cursor-pointer">
                    <div class="relative">
                        <input type="checkbox" v-model="showValidationResults" class="sr-only">
                        <div class="block bg-gray-600 w-7 h-4 rounded-full"></div>
                        <div class="dot absolute left-0.5 top-0.5 bg-white w-3 h-3 rounded-full transition"></div>
                    </div>
                    <div class="ml-3 text-gray-700 font-medium">Failed Validations</div>
                </label>
            </div>
            <slot></slot>
            <!-- Template Content -->
            <template v-if="sortedFormFields != null && RenderFormFields == true" v-for="item in sortedFormFields"
                ref="controlFields">
                <!--class="my-5"-->
                <p class="contact-form-label" v-if="isDebugMode() && showItemDetails"> Type : {{ item.type }}| ID : {{
                    item.id }}| isShow : {{ item.isShown }}| Value : {{ item.value }}</p>
                <p class="contact-form-label" v-if="isDebugMode() && showItemDetails"> Validators : {{
                    validation?.getValidationRule(item.id) }}</p>
                <!-- <p class="contact-form-label" v-if="isDebugMode() && showItemDetails"> Is Valid : {{ validation?.validateRule(item.id) }}</p> -->
                <!-- {{ item.id }} | {{ item.uniqueKey }} | {{ item.isShown }} | {{ item.attributes }} -->

                <div class="gibs-form-group-margin">
                    <GibsControlFormDropdown :key="item.uniqueKey" ref="{{item.id}}"
                        v-if="item.type == 'GibsControlFormDropdown' && item.htmlType != 'MultiSelectTags'"
                        :input-id="item.id" :labelText="fieldConfigLabelEval(item.id)"
                        :options="getDropDownOptions(item.id)" :selectedOption="item.value"
                        :isRequired="fieldConfigOptionalLabelEval(item.id)" :validator="validation"
                        :validator-rule-id="item.id" :show="item.isShown"
                        @dropdownSelection="formDropDownChange(item, $event)">
                    </GibsControlFormDropdown>
                </div>
                <div class="gibs-interest-areas-button-list justify-start" :key="item.uniqueKey"
                        v-if="item.type == 'GibsControlFormDropdown' && item.htmlType == 'MultiSelectTags'">
                        <GibsControlFormBoolean v-for="chcItem in getDropDownOptions(item.id)" 
                            :itemModel="item"
                            ref="{{item.id}}"
                            :yesLabel="getCustomLabel(chcItem, 'yes-label')"
                            :noLabel="getCustomLabel(chcItem, 'no-label')"
                            :yesValue="getCustomValue(chcItem, 'yes-label')"
                            :noValue="getCustomValue(chcItem, 'no-label')"
                            :key="chcItem.id" 
                            :htmlType="item.htmlType" 
                            :isDisabled="false" :input-id="chcItem.id"
                            :labelText="chcItem.itemText" 
                            :isRequired="fieldConfigOptionalLabelEval(item.id)"
                            :modelValue="fieldModel(item, chcItem)" 
                            :validator="validation" 
                            :validator-rule-id="item.id"
                            :show="item.isShown" 
                            :allInterestAreaSelected="chcItem.selected"
                            @radioSelection="formMultiBooleanChange(item, chcItem, $event)">
                        </GibsControlFormBoolean>
                </div>
                <template v-if="item.type == 'GibsControlFormDropdown' && item.htmlType == 'MultiSelectTags'">
                    <div class="gibs-toggle-button-container justify-start">
                        <GibsControlToggle :toggleChecked="allInterestAreaSelected" @toggleEmit="toggleSelectAll" />
                    </div>
                </template>  
                <GibsControlFormInput v-if="item.type == 'GibsControlFormInput'" 
                    ref="{{item.id}}" 
                    type="text"
                    :inputId="item.id" 
                    :labelText="fieldConfigLabelEval(item.id)" 
                    placeholderText="" 
                    hint=""
                    v-model="item.value" 
                    :isRequired="fieldConfigOptionalLabelEval(item.id)" 
                    :validator="validation"
                    :validator-rule-id="item.id" 
                    :show="item.isShown" 
                    :doValidation="doValidation"
                    @input="formTextInputChange(item, $event)"
                    :isDisabled="fieldConfigReadOnlyEval(item.id)"
                    :max-length="item.maxLength??1024">
                </GibsControlFormInput>
                <!-- xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx -->
                <div class="gibs-form-group-margin">
                    <GibsControlFormLookup 
                        :key="item.uniqueKey"
                        :inputId="item.id" 
                        ref="{{item.id}}"
                        v-if="item.type == 'GibsControlFormLookup'" 
                        :itemModel="item" 
                        v-model="formSection"
                        :validator="validation" 
                        :doValidation="doValidation"
                        :ExternalDataSource="props.ExternalDataSource"
                        :validator-rule-id="item.id"  
                        :formLevel="currentFormLevel()"
                        @lookupSelection="formLookupChange(item, $event)" 
                        :alternativeLookup="props.alternativeLookup"
                        class="mb-3.75">
                    </GibsControlFormLookup>
                </div>
                <!-- xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx -->
                <div class="gibs-form-group-margin">
                    <GibsControlFormBoolean 
                        :itemModel="item"
                        :yesLabel="getCustomLabel(item, 'yes-label')"
                        :noLabel="getCustomLabel(item, 'no-label')"
                        :yesValue="getCustomValue(item, 'yes-label')"
                        :noValue="getCustomValue(item, 'no-label')"
                        :key="item.uniqueKey" 
                        ref="{{item.id}}"
                        v-if="item.type == 'GibsControlFormBoolean'" 
                        class="" 
                        :isDisabled="false" 
                        :input-id="item.id"
                        :labelText="fieldConfigLabelEval(item.id)" 
                        :isRequired="fieldConfigOptionalLabelEval(item.id)"
                        v-model="item.value" 
                        :validator="validation" 
                        :validator-rule-id="item.id" 
                        :show="item.isShown"
                        @radioSelection="formBooleanChange(item, $event)">
                    </GibsControlFormBoolean>
                </div>
                <div class="gibs-form-group-margin" v-if="item.type == 'GibsControlDatePicker'">
                    <GibsControlDatePicker 
                        :key="item.uniqueKey" 
                        ref="{{item.id}}" 
                        :input-id="item.id"
                        :labelText="fieldConfigLabelEval(item.id)" 
                        :modelValue="formatDate(new Date(item.value))"
                        :isRequired="fieldConfigOptionalLabelEval(item.id)" 
                        validRange="past-date" 
                        :validator="validation"
                        :show="item.isShown" 
                        :validator-rule-id="item.id"
                        @selectedDateEmit="formDateTimeChange(item, $event)">
                    </GibsControlDatePicker>
                </div>

                <div v-else-if="item.receiver == 'getDivisions' && item.isShown">
                    <div class="gibs-form-group-margin">
                        <GibsControlFormLookup 
                            :key="item.uniqueKey" 
                            :inputId="item.id" 
                            :formLevel="currentFormLevel()"
                            ref="{{item.id}}"
                            :itemModel="item" 
                            v-model="formSection"
                            :validator="validation" 
                            :doValidation="doValidation"
                            :validator-rule-id="item.id"  
                            :awaitOptions="getDivisionsOptions"
                            :useAwaitRefresh="true"
                            :refreshKey="divisionsRefreshKey"
                            @lookupSelection="formLookupChange(item, $event)" 
                            class="mb-3.75">
                        </GibsControlFormLookup>
                    </div>
                </div>
            </template>

            <div class="gibs-save-continue-submit-buttons-container">
                <GibsControlPrimaryButton :primaryButtonSpec="nextButtonSpec" @click="nextSectionButton"
                    v-if="!props.ShouldShowSave && IsNextButtonVisible()" /> 
                <GibsControlPrimaryButton :disabled="setDisabledSubmit" :primaryButtonSpec="submitButtonSpec" @click="submit" v-show="props.ShouldShowSave || isSubmitVisible()" />
                <GibsControlTextLink :textLinkSpec="saveAndContinueButtonSpec" @click="saveAndContinue"
                    v-if="SaveAndContinueVisible && (config?.FormLevel === 'Level3' || config?.FormLevel === 'Level4')" />
                <slot name="footerButtons"></slot>
            </div>

            <pre v-if="isDebugMode() && (showSortedFormFields)">
            <div v-if="showSortedFormFields">{{ sortedFormFields }}</div>
            </pre>
            <p v-if="isDebugMode() && showValidationResults" class="contact-form-label text-bostonred w-fit">
                The following fields failed validation:
                <br />
                <br />
            <div v-for="(result, index) in validationResults" :key="index">
                <pre>{{ result }}</pre>
                <br />
            </div>
            </p>
        </div>
        <pre v-if="isDebugMode()">
            {{ sortedFormFields }}
        <div class="text-bostonred w-fit">{{ validation.validate() }}</div>
        </pre>
    </div>
</template>

<script setup lang="ts">
import { z } from 'zod';
import { useFormsStore } from '~/stores/forms';
import { SubProgrammeConfiguration } from '~/classes/sub-programme-configuration';
import { getUniqueKey, fieldVisibility, isSenderField, isDebugMode } from '~/utils/display-utils';
import { minInputLength } from '~/utils/form-field-utils';

//const easyApplyStore = useEasyApplyStore();
const divisionsRefreshKey = ref(0);
const doValidation = ref<Boolean>(false);
const controlFields = ref(null);
const formSection = defineModel({
    type: Object as () => IFormSection,
    required: true,
});
const emit = defineEmits([
    'saveAndContinue',
    'nextSection',
    'formDropDownChange',
    'formTextInput',
    'formLookupChange',
    'formBooleanChange',
    "subscribeToParentForm",
    "formDateTimeChange",
    "validateAllSections",
    "formFieldValueChange",
    "IsSubmitVisible",
    'formMultiBooleanChange'
]);
const props = defineProps({
    Title: {
        type: String,
        required: true,
    },
    FormFields: {
        type: Array as () => z.infer<typeof FieldInfo>,
        required: true,
    },
    SubmitVisible: {
        type: Boolean,
        required: false,
        default: true,
    },
    SubmitDisabled: {
        type: Boolean,
        required: false,
        default: true,
    },
    SaveAndContinueVisible: {
        type: Boolean,
        required: false,
        default: true,
    },
    RenderFormFields: {
        type: Boolean,
        required: false,
        default: true,
    },
    IsModal: {
        type: Boolean,
        required: false,
        default: false,
    },
    CustomValidation: {
        type: Function,
        required: false,
        default: (isValid: boolean) => { return isValid; }
    },
    ExternalDataSource : {
        type: Function,
        required: false,
        default: (entity : FieldInfoItem) => { return [] }
    },
    SaveButtonText : {
        type: String,
        required: false,
        default: "Submit"
    },
    ShouldShowSave : {
        type: Boolean,
        required: false,
        default: false
    },
    alternativeLookup : {
        type: Function,
        default: (search : string) => { return '' }
    },
    showControl : {
        type: Boolean,
        required: true,
        default: false
    }
});

const savedAreasOfInterest = ref([]);
const savedAreasOfInterestString = ref<string>("");
const isValid = ref(false);
const allInterestAreaSelected = ref<boolean>(false);
const selectedInterestAreaCount = ref<number>(0);

const showItemDetails = ref(false);
const showSortedFormFields = ref(false);
const showValidationResults = ref(false);
const validationResults = computed(() => {
    const results = validation.value?.validate(props.FormFields);
    return results ? results.filter(result => result.result !== true) : [];
});
const modalStyle = computed(() => {
    if (props.IsModal == true) {
        return "";
    }
    else {
        return "gibs-form-content";
    }
});
const setDisabledSubmit = computed(() => {
    return props.SubmitDisabled;
});
const sortedFormFields = ref<Array<typeof FieldInfo>>([]);
const route = useRoute();
const store = useFormsStore();
const { dynamicWebFormValidation } = useValidations();
const sections = ref<Array<IFormSection>>([]);
const subProgrammeConfiguration = ref<SubProgrammeConfiguration | null>(null);
const saveAndContinueButtonSpec: IControlButton = {
    buttonElName: "gibs-general-save-and-continue-form-button",
    buttonText: sentenceCase("Save & Continue later"),
    class: "gibs-save-and-continue-form-button",
    enableUrl: false,
    externalSource: false,
    openNewTab: false,
    sourceUrl: "/",
}
const nextButtonSpec: IControlButton = {
    buttonElName: "gibs-general-save-and-continue-form-button",
    buttonText: sentenceCase("Next"), //"Next => " + formSection.value.NextSection?.Description,
    class: "gibs-next-form-button",
    enableUrl: false,
    externalSource: false,
    openNewTab: false,
    sourceUrl: "/",
}
const submitButtonSpec = ref<IControlButton>({
    buttonElName: "gibs-general-save-and-continue-form-button",
    buttonText: props.SaveButtonText,
    class: "gibs-next-form-button",
    enableUrl: false,
    externalSource: false,
    openNewTab: false,
    sourceUrl: "/",
});

const userParsedData = computed(async ()=>{
        const { data: profile, error, status } = await useLoaderFetch('/api/auth/user', {
            method: 'GET'
        });
        return GibsUserSession.safeParse(profile.value);
    });


let orderFormFields = computed(() => {
    return props.FormFields.sort((a, b) => {
        if (a.order < b.order) {
            return -1
        }
        if (a.order > b.order) {
            return 1
        }
        return 0
    })
});
let  doneLoading = ref(false);
let isLoggedIn = ref(false);
let validated: boolean = false;
let formSlug = (route.params.form instanceof Array) ? route.params.form[0] : route.params.form;
let config: SubProgrammeConfiguration | null;
let validation = ref<InstanceType<typeof Validation> | null>(null);
    function getInitFields() {
        if (props.FormFields?.startsWith("[") || props.FormFields?.startsWith("{"))
        {
            return JSON.parse(JSON.stringify(props.FormFields));
        }
        else{
            return [];
        }
    }
let initFields: z.infer<typeof FieldInfo> =  getInitFields;
let fieldConfigLabelEval = computed(() => {
    return (inputId: string) => { 
        let config = store.SubProgrammeConfig!.getFieldConfig(inputId);
        return (config?.Label) ? config.Label :
            formSection.value.Fields.find(_ => _.FieldId == inputId)?.Label ?? "";
    };
});
let fieldConfigReadOnlyEval = computed(() => {
    return (inputId: string) => {
        let config = store.SubProgrammeConfig!.getFieldConfig(inputId);
        //let isEasyApply = easyApplyStore.getEasyApplyResult(subProgrammeConfiguration?.value?.ProgrammeId);
        
        if (isLoggedIn.value === true && inputId === 'emailaddress1_brite_04_05_emailaddress')
        {
            return true;
        }
        
        return (config?.ReadOnly) ? config.ReadOnly :
            formSection.value.Fields.find(_ => _.FieldId == inputId)?.ReadOnly ?? false;
    };
});
let fieldConfigOptionalLabelEval = computed(() => {
    return (inputId: string) => {
        let config = store.SubProgrammeConfig!.getFieldConfig(inputId);
        return (config?.Validator) ? config.Validator.toLocaleLowerCase() === 'display as mandatory' :
            (formSection.value.Fields.find(_ => _.FieldId == inputId)?.Validator ?? "").toLocaleLowerCase() === 'display as mandatory' ?? false;
    };
});
subProgrammeConfiguration.value = store.SubProgrammeConfig as SubProgrammeConfiguration;
if (subProgrammeConfiguration.value === null) {
    throw new Error("The Programme configuration is null. Unable to load the form. " + props.Title);
}

config = store.SubProgrammeConfig as SubProgrammeConfiguration | null;
validation.value = dynamicWebFormValidation(store.SelectedFormSection!.Fields, props.FormFields);
sections.value = subProgrammeConfiguration!.value.getSectionList();

//BF: This was needed but it looks like we should not
// watchEffect(() => {
//     props.FormFields.forEach((formField) => {
//         changeFormFields(formField, formField.value);
//     },
//         { flush: 'post' });
// });

function getCustomValue(formField: z.infer<typeof FieldInfoItem>, labelType: string) : Number {
    if (formField.id == "gibs_usedivisiononinvoice_brite_05_05_mustdivisionbeoninvioce"){
        if (labelType == "yes-label"){
            return 449710000;
        }
        else if (labelType == "no-label"){
            return 449710001;
        }
    }
    else{
        if (labelType == "yes-label"){
            return 1;
        }
        else if (labelType == "no-label"){
            return 2;
        }
    }
} 

function getCustomLabel(formField: z.infer<typeof FieldInfoItem>, labelType: string) {
    if (formField.attributes == null){
        if (labelType == "yes-label"){
            return "Yes";
        }
        else if (labelType == "no-label"){
            return "No";
        }
    }
    else{

        let returnValue = JSON.parse(formField.attributes).find(_ => _.name == labelType)?.value;
        if (returnValue == null){
            if (labelType == "yes-label"){
                return "Yes";
            }
            else if (labelType == "no-label"){
                return "No";
            }
        }

        return returnValue
    }
}

function IsNextButtonVisible() {
    if (props.IsModal === true) {
        return false;
    }
    else if (formSection.value && formSection.value.NextSection) {
        return formSection.value.NextSectionIndex > 0
    }
    else {
        return false;
    }
}

function isSubmitVisible() {
    if (formSection.value.NextSection == null) {
        return props.SubmitVisible;
    }
    else {
        return false;
    }
}

function IsJson(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

const hasDivisionsOptions = ref(false);
async function getDivisionsOptions() : Promise<[string, string, Array<IFormTypeAheadItem>]>
{
    let companyId = "";
    let companyName = "";
    hasDivisionsOptions.value = false;
    let options: Array<IFormTypeAheadItem> = [];
    let org = props.FormFields.find(_ => _.id === "new_accountdebtor_brite_05_03_organization");
    if (org != null && org.value != null && org.value != '') {
        companyId = IsJson(org.value) ? JSON.parse(org.value).Value : org.value.Value;
        companyName = IsJson(org.value) ? JSON.parse(org.value).Text : org.value.Text;
        if (companyId != '' || companyId != Guid.Empty){
            const response = await useLoaderFetch(`/api/form/getLinkedList?companyId=${companyId}`);
            if (response.data.value != null) {
                response.data.value.responseObjects.forEach(_ => {
                    options.push({ Text: _.divisionName, Value: _.id });
                })
            }
        }
    }
    hasDivisionsOptions.value = true;
    return [companyId, companyName, options];
}

function init() {
    orderFormFields.value.forEach(_ => {
        sortedFormFields.value.push(_);
    });
    if(sortedFormFields.value.length > 0){
 // set saved areas of interest selection into formfields' value field
 if (sortedFormFields.value[0].id === "new_areasofinterest_brite_04_09_areasofinterest") {
        sortedFormFields.value[0].value = savedAreasOfInterestString.value;
    }
    var current = store.SelectedFormSection;
    if (current != null) {
        if (current.PreviousState === FormSectionStates.ValidationFailed) {
            validation.value?.validate(props.FormFields);
        }
    }
   
    }

    emit('subscribeToParentForm', (func: string) => {
        if (func == "saveAndContinue") {
            saveAndContinue();
        }
        if (func == "nextSection") {
            nextSection(false);
        }
    });
}

function saveAndContinue() {
    let hasChanges = areObjectsEqualDeep(initFields, props.FormFields);
    emit('saveAndContinue', props.FormFields, !hasChanges, false);
}

function nextSection(doRedirect: boolean = true) {
    let hasChanges = false;
    hasChanges = areObjectsEqualDeep(initFields, props.FormFields);
    emit('nextSection', props.FormFields, !hasChanges);
    validateAllSections(false);
}

function nextSectionButton() {
    validateAllSections(false);
    store.setSelectedFormSection(formSection.value.NextSection);
    scrollToTop();
}

function submit() {
    validateAllSections(true);
    emit('saveAndContinue', props.FormFields, true, true);
}

function validateAllSections(showErrors: boolean = true) {
    emit("validateAllSections", showErrors);
}

function nextID(): number {
    let nextNumber = -1;
    try {
        if (props.FormFields.length > 0) {
            var currentSection = props.FormFields[0].section
            if (currentSection) {
                var current = sections.value.find(_ => _.Description.toLowerCase() === currentSection.toLowerCase());
                if (current) {
                    nextNumber = sections.value.indexOf(current) + 1;
                    if (sections.value[nextNumber] != null) {
                        if (sections.value[nextNumber].Visible === undefined) {
                            sections.value[nextNumber].Visible = false;
                        }
                        if (sections.value[nextNumber].Visible === false) {
                            nextNumber = nextNumber + 1;
                        }
                    }
                }
            }

            if (nextNumber >= sections.value.length) {
                nextNumber = -1;
            }
        }
    }
    catch (ex) {
        console.log(ex);
    }
    return nextNumber;
}


function checkFormState(formField: z.infer<typeof FieldInfoItem>) {
    if (validated === false && doneLoading.value === true) {
        if (formSection.value.State === FormSectionStates.Completed ||
            formSection.value.State === FormSectionStates.Optional ||
            formSection.value.State === FormSectionStates.InProgress ||
            formSection.value.State === FormSectionStates.ValidationFailed) {
                
            validation.value = dynamicWebFormValidation(formSection.value.Fields, props.FormFields);
            var isValid = validation.value.isValid(props.FormFields); 
            if (!isValid) {
                for (let _ of validationResults.value) {
                    let control = document.getElementById(_.validationRuleId) as HTMLElement;
                    const errorElement = document.getElementById(`${_.validationRuleId}-error`) as HTMLElement;
                    if (control != null) {
                        control.classList.add("has-error");
                    }
                    if (errorElement != null) {
                        errorElement.innerText = validation.value.getValidationResultString(_.validationRuleId);
                    }
                    // else{
                    //     debugger;
                    // }
                };
            }
            isValid = props.CustomValidation(isValid);

            console.log("checkFormState : isValid: " + isValid + " " + formSection.value.Description + " " + formSection.value.State);
            formSection.value.State = isValid ? FormSectionStates.Completed : FormSectionStates.ValidationFailed;
        }
        validated = true;
    }
}


function formDropDownChange(formField: z.infer<typeof FieldInfoItem>, input: IDropdownItem) {
    let oldValue = formField.value;
    const validationValue = input.id;
    setValidationValue(formField.id, validationValue, 'formDropDownChange');
    //uniqueKey.value = getUniqueKey();
    formField.value = validationValue;
    changeFormFields(formField, input, (oldValue != validationValue));


    emit('formDropDownChange', formField);
}

function formTextInputChange(formField: z.infer<typeof FieldInfoItem>, input: string) {
    let oldValue = formField.value;
    setValidationValue(formField.id, input, 'formTextInput');
    //uniqueKey.value = getUniqueKey();
    formField.value = input;

    changeFormFields(formField, input, (oldValue != input));
    emit('formTextInput', formField);
}

function newItem(formField: z.infer<typeof FieldInfoItem>, input: IFormTypeAheadItem | null) {
    emit("formFieldValueChange", formField);
}

function formLookupChange(formField: z.infer<typeof FieldInfoItem>, input: IFormTypeAheadItem | null) {
    let oldValue = formField.value;
    const validationValue = input;
    setValidationValue(formField.id, validationValue, 'formFieldValueChange');
    formField.value = input;
    // THIS IS A HACK TO PREVENT THE LOOKUP CONTROL TO RERENDER WHEN THE VALUE IS BLANK, AS IT PREVENTS THE VALIDATION TO SHOW 
    let forceRefresh = (oldValue != input);
    if (forceRefresh) {

        forceRefresh = (input['Value'] != Guid.Empty)
    }
    
    if (formField.id === "gibs_customerdivision_brite_05_04_divisionasontheinvoice") {
        forceRefresh = false;
    }
 
    changeFormFields(formField, input, forceRefresh);
    if (formField.id === "new_accountdebtor_brite_05_03_organization") {
        divisionsRefreshKey.value = getUniqueKey(divisionsRefreshKey.value);
    }
}

function formBooleanChange(formField: z.infer<typeof FieldInfoItem>, input: string) {
    let oldValue = formField.value;
    setValidationValue(formField.id, input, 'formBooleanChange');
    formField.value = input;
    //uniqueKey.value = getUniqueKey();
    if (typeof oldValue === 'boolean') {
        oldValue = "0";
    }
    changeFormFields(formField, input, (oldValue != input));

    emit("IsSubmitVisible", formField.value);
    emit('formBooleanChange', formField, (oldValue != input));
}

function fieldModel(formField: z.infer<typeof FieldInfoItem>, inputId: string | IDropdownItem) {
    let selection = [];
    if (formField.htmlType === CRMControlType.Boolean) {
        if (formField.value != '') {
            selection = JSON.parse(formField.value);
            if (selection.find(_ => _.value == inputId)) {
                return FormBooleanValues.True;
            }
        }
        return FormBooleanValues.False;
    } else if (formField.htmlType === CRMControlType.MultiSelectTags) {
        if (formField.value != '') {
            selection = JSON.parse(formField.value);
            if (typeof (selection.find(_ => _.value == inputId.id)) != "undefined") {
                inputId.selected = true;
            } else {
                inputId.selected = false;
            }
        }
        return inputId;
    }
}

function formMultiBooleanChange(formField: z.infer<typeof FieldInfoItem>, checkItem: z.infer<typeof IDropdownItem>, input: string) {
    let oldValue = formField.value;
    let selection = [];
    if (formField.value != '') {
        selection = JSON.parse(formField.value);
    }
    if (input.toString() === getCustomValue(checkItem, 'yes-label').toString()) {
        if (!checkItem.selected) {
            selection.push({ Text: checkItem.itemText, value: checkItem.id });
        }
    }
    else {
        selection = selection.filter((_: any) => _.value !== checkItem.id);
    }
    selectedInterestAreaCount.value = selection.length;

    formField.value = JSON.stringify(selection);
    changeFormFields(formField, input, true);
    // formField.uniqueKey = getUniqueKey();



    emit('formMultiBooleanChange', formField);
}

function toggleSelectAll(emit: boolean) {
    const booleanString = emit ? FormBooleanValues.True : FormBooleanValues.False;
    allInterestAreaSelected.value = emit;
    selectedInterestAreaCount.value = emit ? formSection.value.Fields[0].DropDownOptions.length : 0;

    getDropDownOptions("new_areasofinterest_brite_04_09_areasofinterest").forEach((item: any) => {
        formMultiBooleanChange(sortedFormFields.value[0], item, booleanString);
    })
}

function getSelectedItemsCount() {
    if (formSection.value.Fields.length > 0){
        if (formSection.value.Fields[0].DropDownOptions)
        {
            selectedInterestAreaCount.value = formSection.value.Fields[0].DropDownOptions.filter((item: any) => {
                return item.selected === true;
            }).length;
        }
        setToggle();
    }
}

function setToggle() {
    if (formSection.value.Fields[0].DropDownOptions)
    {
        if (selectedInterestAreaCount.value === formSection.value.Fields[0].DropDownOptions.length) {
            allInterestAreaSelected.value = true;
        } else {
            allInterestAreaSelected.value = false;
        }
    }
    else
    {
        allInterestAreaSelected.value = false;
    }
}

function formDateTimeChange(formField: z.infer<typeof FieldInfoItem>, input: Date) {
    let oldValue = formField.value;
    setValidationValue(formField.id, input, 'formDateTimeChange');
    const dateOnly = new Date(Date.UTC(input.getFullYear(), input.getMonth(), input.getDate(), 12, 0, 0));
    formField.value = dateOnly;
    
    changeFormFields(formField, dateOnly);
    //uniqueKey.value = getUniqueKey();

    emit('formDateTimeChange', formField, (oldValue != dateOnly));
}

function setValidationValue(formFieldId: string, value: any, emitEvent: string) {
    let fields = props.FormFields;
    var field = fields.find(_ => _.id == formFieldId);
    if (field) {
        field.value = syncFormFieldValue(fields, field, value); // Set all fields on this section that match the field by entityMappings
        emit(emitEvent, field);
    }
}

function getDropDownOptions(key: string) {
    if (config) {
        var fields = config?.getFieldConfig(key);
        if (fields) {
            if((config?.FormLevel == FormLevel.Level1 || config?.FormLevel == FormLevel.Level2 || config?.FormLevel == FormLevel.Level3) && key == "new_responsibleforaccount_brite_05_01_responsibleforaccount"){
                return fields.DropDownOptions.filter(
                    (option) => option.id === '100000000' || option.id === '100000001'
                );
            }
            else
            {
                return fields.DropDownOptions;
            }
            
        }
    }
}

function currentFormLevel() {
    if (config) 
    {
        return config.FormLevel;
    }
    else
    {
        return "";
    }
}

function getLookupField(key: string) {
    if (config) {
        var fields = config?.getFieldConfig(key);
        if (fields) {
            return fields;
        }
    }
}

function scrollToTop() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
}

function changeFormFields(formField: z.infer<typeof FieldInfoItem>, value: string | Date | undefined, forRefresh: boolean = false) {
    if (typeof (formField) !== 'undefined') {
        emit("formFieldValueChange", formField);
    }

    let currentUniqueKey = formField.uniqueKey;
    let foundItems = sortedFormFields.value.filter(_ => isSenderField(_.sender, formField.id, _));
    if (foundItems != null && foundItems.length > 0) {
        foundItems.forEach(_ => {
            fieldVisibility(_, sortedFormFields.value, forRefresh);
        });
        if (forRefresh === true) {
            formField.uniqueKey = getUniqueKey(formField.uniqueKey);
        }
    }
    else if (forRefresh === true) {
        formField.uniqueKey = getUniqueKey(formField.uniqueKey);
    }

    // console.log("changeFormFields");
    formField.dateLastChange = new Date();
    if (doneLoading.value === true && currentUniqueKey != formField.uniqueKey) { //validated && forRefresh &&
        validated = false;
        checkFormState(formField);
    }
}

function applySavedInterestAreas() {
    if(formSection.value.Fields.length > 0)
    {
        if (formSection.value.Fields[0].DropDownOptions?.length > 0)
        {
            formSection.value.Fields[0].DropDownOptions.map(field => {
                if (field.selected === true) {
                    let selectedField = {
                        Text: field.itemText,
                        value: field.id
                    }
                    savedAreasOfInterest.value.push(selectedField)
                }
            });
        }
        savedAreasOfInterestString.value = savedAreasOfInterest.value.length > 0? JSON.stringify(savedAreasOfInterest.value) : "";
    }
}

async function doMounted(){
    if (formSection.value != undefined){
        doValidation.value = (formSection.value.State === FormSectionStates.ValidationFailed);
        console.log("FormSection: " + formSection.value.Description + " " + formSection.value.State);
        applySavedInterestAreas();
        init()
        if (doValidation.value == true){
            validateAllSections(true);
        }
        doneLoading.value = true;
        getSelectedItemsCount();
    }

    let parsedData = await userParsedData.value;
    if (!parsedData.success || parsedData.data.isLoggedIn === false) {
        isLoggedIn.value = false;
    }
    else{
        isLoggedIn.value = true; 
    }
}

onMounted(async () => {
    await doMounted();
});



//init();

watch(() => selectedInterestAreaCount.value, () => {
    setToggle();
});

watch(() => props.SubmitDisabled, (newVal) => {
    isValid.value = newVal; 
}); 

watch(() => props.showControl, (newVal) => {
    if (props.showControl === true && doneLoading.value == false){
        doMounted();
    }
    else if (props.showControl === true)
    {
        doValidation.value = (formSection.value.State === FormSectionStates.ValidationFailed);
        if (doValidation.value == true){
            validateAllSections(true);
        }
    }
}); 
</script>

<style scoped>
input:checked~.dot {
    transform: translateX(100%);
}

input:checked~.block {
    background-color: #48bb78;
}
</style>