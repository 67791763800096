<template>
    <template v-if="props.solidButtonSpec.enableUrl">
        <NuxtLink :id="props.solidButtonSpec.buttonElName" class="gibs-solid-button" :class="props.solidButtonSpec.class"
            :external="props.solidButtonSpec.externalSource" :to="props.solidButtonSpec.sourceUrl"
             :target="openLinkInNewTab()"  > <!-- @click.prevent="buttonClicked" -->
            <template v-if="!props.solidButtonSpec.load">
                <span >
                    {{ props.solidButtonSpec.buttonText }}
                </span>
            </template>
            <template v-else>
                <span :class=props.loaderClass>
                    <span class="loader"></span>
                </span>
            </template>
        </NuxtLink>
    </template>
    <template v-else>
        <button :disabled="props.solidButtonSpec.disabled" 
                type="button" :id="props.solidButtonSpec.buttonElName"
                class="gibs-solid-button-click-event" 
                :class="{ 'bg-cultured': props.solidButtonSpec.load, [props.solidButtonSpec.class]: true }">
            <template v-if="!props.solidButtonSpec.load">
                <span>
                    {{ props.solidButtonSpec.buttonText }}
                </span>
            </template>
            <template v-else>
                <span class='button-spinner-container'>
                    <span class="loader"></span>
                </span>
            </template>
        </button>
    </template>
</template> 
  
<script setup lang="ts">

//const loadingRef = ref<typeof GibsControlLoading>();
const store = useFormsStore();

const props = defineProps({
    solidButtonSpec: {
        type: Object,
        default: buttonObject
    },
    loaderClass: {
        type: String,
        default: 'button-spinner-container'
    }
});

// function buttonClicked(){
//     loadingRef.value = store.getLoader().value;
//     loadingRef.value.showSpinnerFor();
// }

function openLinkInNewTab(): string {
    //loadingRef.value = store.getLoader().value;
    //loadingRef.value.showSpinnerFor(500);
    
    return props.solidButtonSpec.openNewTab ? '_blank' : '';
}
</script>

<script lang="ts">
var buttonObject: IControlButton = {
    buttonElName: "solid-button",
    buttonText: "Find out more",
    class: "",
    enableUrl: true,
    externalSource: false,
    openNewTab: false,
    sourceUrl: "/",
    load: false,
    disabled: false
}
</script>