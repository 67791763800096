import { z } from 'zod';
import deepEqual from 'deep-equal';

export const EntityMapping = z.object({
  entity: z.string(),
  attribute: z.string(),
});

export const AttributeMappings = z.array(EntityMapping);

export const AttributeMapping = z.object({
  name: z.string().nullable(),
  value: z.string().nullable(),
});

export const FieldInfoItem =  z.object({
    id: z.string(),
    entityMappings: z.array(EntityMapping),
    order: z.number(),
    type: z.string().refine((val) => ['GibsControlFormDropdown', 'GibsControlFormInput', 'GibsControlFormLookup', 'GibsControlFormBoolean', 'GibsControlDatePicker']).default('GibsControlFormInput'),
    labelText: z.string(),
    value: z.string().or(z.date().optional()).nullable(),
    valueText: z.string().optional(). nullable(),
    section: z.string(),
    isShown: z.boolean(),
    dateLastChange: z.date().default(new Date(1900, 1, 1)).optional(),
    htmlType: z.string().optional().nullable(),
    attributes : AttributeMappings.optional().nullable(),
    sender: z.string().optional().nullable(),
    receiver: z.string().optional().nullable(),
    uniqueKey : z.number().default(0).optional(),
    alternativeAtttribute : z.string().optional().nullable(),
    alternativeEntity : z.string().optional().nullable(),

    isExternalValidation: z.boolean().optional().nullable(),
    externalValidationState: z.string().optional().nullable(),
    externalValidation : z.function().optional().nullable(),
    isHidden : z.boolean().optional().default(false).nullable(),
});

export const FieldInfo = z.array(FieldInfoItem);

export const areObjectsEqualDeep = (obj1: z.infer<typeof FieldInfo>, obj2: z.infer<typeof FieldInfo>): boolean => {
  try{
    let isEqual = deepEqual(obj1, obj2);
    return isEqual;
  }
  catch(ex){
    console.log(ex);
    return false;
  }
};

