<template>
    <template v-if="props.htmlType == CRMControlType.Boolean">
        <div class="form-group" :class="{'hide': (!props.show)}">
            <label :for="inputId" class="contact-form-label">{{ labelText }}
                <span v-if="!props.isRequired" class="optional-text"> (optional)</span>
            </label>
            <div class="form-group-container">
                <div class="gibs-boolean-item-container">
                        <div class="gibs-boolean-item-wrapper">
                            <input type="radio" 
                                            :disabled="props.isDisabled"
                                            class="gibs-radio-item-input"
                                            :id="`${inputId}-yes`"
                                            :name="`${inputId}`"  
                                            :value="props.yesValue" 
                                            @click="handleChange($event)"
                                            :checked="isYesChecked">
                            <label class="gibs-radio-item-label">{{props.yesLabel}}</label>
                        </div>
                        <div class="gibs-radio-item-wrapper">
                            <input type="radio" 
                                            :disabled="props.isDisabled"
                                            class="gibs-radio-item-input"
                                            :id="`${inputId}-no`"
                                            :name="`${inputId}`"  
                                            :value="props.noValue" 
                                            @click="handleChange($event)"
                                            :checked="isNoChecked">
                            <label class="gibs-radio-item-label">{{$props.noLabel}}</label>
                        </div>
                </div>                
            </div>
            <p :id="`${inputId}-error`" class="gibs-form-group-error gibs-small-regular"></p>
            <p class="hint-text">{{ hint }}</p>
        </div>
    </template>
    <template v-else-if="props.htmlType == CRMControlType.MultiSelectTags">
        <GibsControlInterestAreaGhostButton
            :buttonSpec="mapInterestAreaButton(interestAreaItem)" 
            :selected="interestAreaItem.selected"
            @interestAreaEmit="handleChange($event)"/>
    </template>
    <template v-if="showLoader">
        <span class="gibs-submit-loader">
            <span class="loader"></span>
        </span>
    </template>
</template>
<script lang="ts" setup scope>
    import { mapInterestAreaButton } from '~/utils/form-field-utils';
    import GibsControlLoading from '~/components/gibs/control/loading.vue';

    const props = defineProps({
        itemModel: {
            type: Object as () => typeof FieldInfoItem,
            required: true,
        },
        inputId: {
            type: String,
            required: true,
        },
        labelText: {
            type: String,
            default: '',
            required: true,
        },
        hint: {
            type: String,
            default: '',
        },
        isRequired: {
            type: Boolean,
            default: false,
        },
        modelValue: {
            default: '',
        },
        validator: {
            type: Validation,
            default: null,
        },
        validatorRuleId: {
            type: String,
            default: null
        },
        isDisabled: {
            type: Boolean,
            default: false
        },
        show: {
            type: Boolean,
            default: true
        },
        htmlType: {
            type: String,
            default: "Boolean"
        },
        allInterestAreaSelected: {
            type: Boolean,
            default: false
        },
        yesLabel: {
            type: String,
            default: "Yes"
        },
        noLabel: {
            type: String,
            default: "No"
        },
        yesValue: {
            type: Number,
            default: 1
        },
        noValue: {
            type: Number,
            default: 2
        }
    });

    const store = useCourseReferenceStore();
    await store.fetchInterestAreaReferences();

    const showLoader = ref<boolean>(false);
    const interestRef = await store.fetchCourseInterestAreaReferences();
    const color = ref(interestRef.find(item => {
        return item.id === props.modelValue.id;
    }));
    const interestAreaItem = ref<IFilterItem>({
        filterType: "",
        id: props.modelValue.id,
        itemText: props.modelValue.itemText,
        selected: props.modelValue.selected,
        colour: color.value? color.value.text : ""
    });

	const emitFormBoolean = defineEmits<{
		radioSelection: [value: string];
	}>();

    const isYesChecked = ref<boolean>(false);
    const isNoChecked = ref<boolean>(false);

    function valuateMultiChecked() {
        interestAreaItem.value = {
            filterType: "",
            id: props.modelValue.id,
            itemText: props.modelValue.itemText,
            selected: props.modelValue.selected,
            colour: color.value? color.value.text : ""
        }
    }

    function valulateIsChecked() { 
        let modelValueChecked = props.modelValue;
        // TODO : Devlin Validate This
        // if (modelValueChecked == ''){
        //     modelValueChecked = props.noValue;
        // }
        
        if (typeof modelValueChecked == 'boolean')
        {
            if (props.modelValue === true){
                modelValueChecked = props.yesValue;
            }
            else if (props.modelValue === false){
                modelValueChecked = props.noValue
            }
        }
        

        if (modelValueChecked === props.yesValue) {
            isYesChecked.value = true;
            isNoChecked.value = false;
        } else if (modelValueChecked === props.noValue) {
            isYesChecked.value = false;
            isNoChecked.value = true;
        }
        else if (modelValueChecked === '1'){
            isYesChecked.value = true;
            isNoChecked.value = false;
        }
        else if (modelValueChecked === '2'){
            isYesChecked.value = false;
            isNoChecked.value = true;
        } 
        else {
            isYesChecked.value = false;
            isNoChecked.value = false;
        }

        // TODO : Devlin Validate This
        if (modelValueChecked != "")
        {
            props.itemModel.value = modelValueChecked;
        }
    }

    function handleChange(event:PointerEvent | {id: string, itemText: string, selected: boolean}) {
        let timer: number = 0;
        const inputElement = document.getElementById(props.inputId + "-no") as HTMLInputElement;
        if (props.itemModel.id == "gibs_acceptlegalterms_brite_21_01_terms" && props.itemModel.value != "1") {
            timer = 1000;
            showLoader.value = true;
            inputElement.disabled = true;
        }
        setTimeout(() => {
            const elementValue = ref();
        
            if (props.htmlType == CRMControlType.Boolean) {
                const element = event.target as HTMLInputElement;
                elementValue.value = element.value;
                if (typeof (props.validator) !== 'undefined' && props.validator != null) {
                    props.validator.setValidationRuleSourceValue(props.validatorRuleId, elementValue.value);
                    let resultList = props.validator.validateRule(props.validatorRuleId);
        
                    ApplyValidate(!props.validator.isValidationRuleValid(props.validatorRuleId), resultList);
                }
            } else if (props.htmlType == CRMControlType.MultiSelectTags) {
                elementValue.value = event.selected? props.yesValue : props.noValue;
                if (typeof (props.validator) !== 'undefined' && props.validator != null) {
                    props.validator.setValidationRuleSourceValue(props.validatorRuleId, elementValue.value);
                    let resultList = props.validator.validateRule(props.validatorRuleId);
        
                    ApplyValidate(!props.validator.isValidationRuleValid(props.validatorRuleId), resultList);
                }
            } 
            emitFormBoolean('radioSelection', elementValue.value);
            showLoader.value = false;
            inputElement.disabled = false;
        }, timer);
	};

    function ApplyValidate(isInvalid: boolean, validationResultList: Array<IValidationResult>) {
        const inputElements = document.getElementsByName(props.inputId) as NodeList;
        const errorElement = document.getElementById(`${props.inputId}-error`) as HTMLElement;

        if (isInvalid) {
            inputElements.forEach((inputElement) => {
                let element = inputElement as HTMLInputElement;
                element.classList.add("has-error");
            });
        }
        else {
            inputElements.forEach((inputElement) => {
                let element = inputElement as HTMLInputElement;
                element.classList.remove("has-error");
            });
        }

        let errorString = '';
        if (typeof (props.validator) !== 'undefined' && props.validator != null) {
            errorString = props.validator.getValidationResultString(props.validatorRuleId);
        }
        if (errorElement) {
            errorElement.innerText = errorString;
        }
    }

    onMounted(()=> {
        if (props.htmlType === CRMControlType.Boolean) {
            valulateIsChecked();
        } else if(props.htmlType === CRMControlType.MultiSelectTags) {
            valuateMultiChecked();
        }
    })
    watch(()=> props.modelValue, ()=> {
        if (props.htmlType === CRMControlType.Boolean) {
            valulateIsChecked();
        } else if(props.htmlType === CRMControlType.MultiSelectTags) {
            valuateMultiChecked();
        }
    })
    watch(() => props.allInterestAreaSelected, () => {
        interestAreaItem.value.selected = props.allInterestAreaSelected;
        const updateItem = {
            id: interestAreaItem.value.id, 
            itemText: interestAreaItem.value.itemText, 
            selected: interestAreaItem.value.selected
        }
        handleChange(updateItem);
    })
</script>